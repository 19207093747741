import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonIcon from './IcomoonIcon';
import { color } from '../../utils/style';

const TextLink = styled('a')`
  span {
    display: inline-block;
    vertical-align: top;
    line-height: 1.2;
    color: ${color.white};
  }
  &.left-icon {
    svg {
      margin-right: 10px;
      margin-left: 0;
    }
  }
`;

const ButtonLink = ({
  to,
  text,
  external,
  className,
  iconName,
  iconLeft,
  iconSize,
  customHandler,
  ariaText,
  iconColor,
}) => {
  const href = !external && to !== '/' ? `/${to}` : to;
  const classes = iconLeft ? `${className} left-icon` : className;
  const target = external ? { target: '_blank' } : '';
  return (
    <TextLink className={classes} href={href} onClick={customHandler} {...target} aria-label={ariaText}>
      {iconName && iconLeft && <IcomoonIcon icon={iconName} color={iconColor} size={iconSize} alt="button link" />}
      {text && <span>{text}</span>}
      {iconName && !iconLeft && <IcomoonIcon icon={iconName} color={iconColor} size={iconSize} alt="button link" />}
    </TextLink>
  );
};

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  external: PropTypes.bool,
  className: PropTypes.string,
  iconName: PropTypes.string,
  iconLeft: PropTypes.bool,
  iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customHandler: PropTypes.func,
  ariaText: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
};

ButtonLink.defaultProps = {
  external: false,
  className: 'button',
  iconName: 'arrow-right',
  iconLeft: false,
  iconSize: 18,
  iconColor: color.white,
  customHandler: () => {},
};

export default ButtonLink;
